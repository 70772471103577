<template>
  <div class="wrap">
    <DetailPage
      ref="detailPage"
      v-model="options"
      @handleEvent="handleEvent"
    ></DetailPage>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
    <div v-show="false">{{ factMoney }}</div>
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import DetailPage from "@/components/billDetailPage"; //表格页面组件
import { mixin } from "@/components/billDetailPage/mixin.js";
import {
  vipFillSave,
  vipFillUpdate,
  vipFillUpdateStatus,
  vipFillGetDetail,
  vipCardBasicList,
} from "@/api/vip/bill/fill";
import { fcount } from "@/utils";
import { getTimeDate } from "@/utils/newDate/index.js"; //年月日时分秒
export default {
  name: "FillDetail",
  mixins: [mixin],
  components: {
    DetailPage,
    Dialog,
  },
  data() {
    return {
      //弹窗配置
      dialogOptions: {
        title: "选择会员",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      requests: {
        add: vipFillSave,
        update: vipFillUpdate,
        updateStatus: vipFillUpdateStatus,
        detail: vipFillGetDetail,
      },
      options: {
        tableTitle: "会员充值列表",
        mutiSelect: true,
        type: "FillDetail",
        quitPageName: "Fill",
        loading: false,
        check: [], // 选中数据
        rowKey: "billId",
        labelWidth: 90,
        list: [{}],
        disabled: false,
        listName: "billDetailItems",
        showProp: "vipNo",
        billType: 150201,
        billNo: "",
        billDate: "",
        fillMoney: "",
        presentMoney: "",
        payModeId: 1,
        factMoney: "",
        billRemark: "",
        tableIndex: 0,
        tableKey: "vipId",
        //基本信息
        formItemOpt: [
          {
            label: "单据编号",
            type: "input",
            model: "billNo",
            disabled: true,
            originalDisabled: true,
            rules: [
              {
                required: true,
                message: "请输入单据编号",
                trigger: ["blur", "change"],
              },
            ],
          },
          {
            label: "单据日期",
            type: "date",
            model: "billDate",
            rules: [
              {
                required: true,
                message: "请输入单据日期",
                trigger: ["blur", "change"],
              },
            ],
          },
          {
            label: "默认充值金额",
            type: "input",
            model: "fillMoney",
            labelWidth: "100px",
            maxlength: 40,
          },
          {
            label: "默认赠送金额",
            type: "input",
            model: "presentMoney",
            labelWidth: "100px",
            maxlength: 40,
          },
          this.$select({
            key: "listPayClass",
            option: {
              filter: "payModeIds",
              model: "payModeId",
              label: "收款方式",
              option: {
                remoteBody: (res) => {
                  console.log("什么数据", res);
                  return {
                    ...res,
                    pageSize: 9999,
                  };
                },
                remoteKey: (res) => {
                  const { rows } = res || {};
                  return (
                    rows.filter(
                      (i) =>
                        [1, 10, 8].includes(i.payModeId) ||
                        (Number(i.payModeId) > 50 && i.isDisplay)
                    ) || []
                  );
                },
              },
              rules: [
                {
                  required: true,
                  message: "请选择收款方式",
                  trigger: ["blur", "change"],
                },
              ],
            },
          }),
          {
            label: "实付金额",
            type: "input",
            model: "factMoney",
            maxlength: 40,
            disabled: true,
            originalDisabled: true,
          },
          {
            label: "单据备注",
            type: "textarea",
            filter: "billRemark",
            model: "billRemark",
            option: {
              maxlength: "80",
              placeholder: "单据备注(1-80字符之间)",
            },
          },
        ],
        //按钮
        buttons: [
          {
            click: "save",
            label: "保存",
            type: "primary",
          },
          {
            click: "save-add",
            label: "保存并新增",
            type: "primary",
          },
          {
            click: "audit",
            label: "审核",
          },
          {
            click: "exportFile",
            label: "导出",
            disabled: () => !this.options.billStatus,
          },
          {
            click: "batchUpdate",
            label: "批量修改",
            disabled: () => ["2", "3", 2, 3].includes(this.options.billStatus),
          },
          {
            click: "batchDel",
            label: "批量删除",
            disabled: () => ["2", "3", 2, 3].includes(this.options.billStatus),
          },
          {
            click: "quit",
            label: "退出",
            disabled: () => false,
          },
        ],
        //表格
        columns: [
          {
            prop: "vipNo",
            label: "会员卡号",
            minWidth: 140,
            align: "center",
            type: "remoteSelect",
            disabled: false,
            option: this.$select({
              key: "vip",
              option: {
                option: {
                  remoteBody: {
                    cardStatus: "0",
                  },
                  remote: vipCardBasicList,

                  change: (val, obj, columnIndex) => {
                    const isRepetition = this.options.list.some(
                      (item) => item.vipId == obj.vipId
                    );
                    if (!isRepetition) {
                      this.options.showItem = [obj];
                      Object.assign(this.options.list[obj.index], {
                        ...obj,
                        fillMoney: this.options.fillMoney || "",
                        presentMoney: this.options.presentMoney || "",
                        remark: this.options.remark || "",
                        allFillMoney: fcount(
                          [
                            obj.totalUsableMoney,
                            this.options.fillMoney,
                            this.options.presentMoney,
                          ],
                          "+"
                        ),
                      });
                    } else {
                      this.$set(this.options.list, obj.index, {});
                      this.$message.warning("请选择不同的会员");
                    }
                    const xTable = this.$refs.detailPage.$refs.vxeTable.$refs.xTable;
                    xTable.setEditRow(xTable.getData()[obj.index]);
                    xTable.setCurrentRow(xTable.getData()[obj.index]);
                  },
                  tableChange: (propValue, row) => {
                    this.handleEvent("getSelectData", row);
                  },
                  buttons: [
                    {
                      type: "more",
                      option: {
                        title: "选择会员",
                        width: 1250,
                        type: "TreeAndTable",
                        formData: {
                          ...this.$dialog({ key: "vip" }),
                          table: {
                            ...this.$dialog({ key: "vip" }).table,
                            mutiSelect: true,
                            isAllSelect: true,
                            list: vipCardBasicList,
                            pagination: {
                              ...this.$dialog({ key: "vip" }).table.pagination,
                              cardStatus: "0",
                            },
                          },
                        },
                      },
                    },
                  ],
                },
              },
            }).option,
            fixed: "left",
            rules: true,
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 150,
            align: "center",
            fixed: "left",
          },
          {
            prop: "vipLevelNo",
            label: "级别编码",
            minWidth: 120,
            align: "center",
            fixed: "left",
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 120,
            align: "center",
            fixed: "left",
          },
          {
            prop: "tel",
            label: "手机号",
            minWidth: 120,
            align: "center",
            fixed: "left",
          },
          {
            prop: "cardStatusName",
            label: "状态",
            minWidth: 120,
            align: "center",
            fixed: "left",
          },
          {
            prop: "totalUsableMoney",
            label: "总余额",
            minWidth: 120,
            align: "center",
            fixed: "left",
          },
          {
            prop: "fillMoney",
            label: "充值金额",
            minWidth: 120,
            align: "center",
            type: "input",
            input: "changeFillMoney",
            disabled: false,
            rules: true,
          },
          {
            prop: "presentMoney",
            label: "赠送金额",
            minWidth: 120,
            align: "center",
            type: "input",
            input: "changePresentMoney",
            disabled: false,
            rules: true,
          },
          {
            prop: "allFillMoney",
            label: "充值后总余额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 200,
            align: "center",
            type: "input",
            input: "sumBalance",
            notVerifyNum: true,
            disabled: false,
          },
        ],
        checkMethod: ({ row }) => !this.options.disabled,
        //校验
        rules: {
          vipNo: [{ required: true, message: "请选择会员" }],
          fillMoney: [{ required: true, message: "请输入充值金额" }],
        },
        summary: ["totalUsableMoney", "fillMoney", "presentMoney", "allFillMoney"],
      },
    };
  },
  async created() {
    this.init();
  },
  computed: {
    factMoney() {
      this.options.factMoney =
        this.options.list?.reduce(
          (moeny, j) => (moeny += Number(j?.fillMoney) || 0),
          0
        ) || 0;
      return this.options.factMoney;
    },
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "sumFactMoney":
          this.options.factMoney =
            this.options.list?.reduce(
              (moeny, j) => (moeny += Number(j?.fillMoney) || 0),
              0
            ) || 0;
          break;
        case "changePresentMoney":
        case "changeFillMoney":
          this.$set(
            this.options.list[row.index],
            "allFillMoney",
            fcount([row.totalUsableMoney, row.fillMoney, row.presentMoney], "+")
          );
          this.handleEvent("sumFactMoney");
          break;
        case "tableIndex":
          console.log("到这里了吗", row);
          this.options.tableIndex = row;
          break;
        case "getSelectData":
          if (!row || !row.check || row.check.length <= 0) return;
          const { check } = row;
          {
            console.log("下标", this.options.tableIndex);
            this.options.columns[0].option.showItem = check;
            let tableIndex = this.options.tableIndex;
            this.options.tableIndex = undefined;
            const vipIds = this.options.list.map((item) => item.vipId);
            this.options.list.splice(
              tableIndex,
              1,
              ...check
                .filter((item) => !vipIds.includes(item.vipId))
                .map((item) => ({
                  ...item,
                  fillMoney: this.options.fillMoney,
                  remark: this.options.billRemark || "",
                  presentMoney: this.options.presentMoney,
                  allFillMoney: fcount(
                    [
                      item.totalUsableMoney,
                      this.options.fillMoney,
                      this.options.presentMoney,
                    ],
                    "+"
                  ),
                }))
            );
          }
          break;
        case "save":
        case "save-add":
        case "audit":
        case "termination":
          try {
            await this.handleForm(type, row);
          } catch (err) {}
          break;
        case "batchUpdate":
          {
            if (["2", "3"].includes(this.options.billStatus)) {
              return this.$message.warning("请操作未审核的单据");
            }
            this.options.check = this.$refs.detailPage.$refs.vxeTable.$refs.xTable.getCheckboxRecords();
            if (!this.options.check.length) {
              return this.$message.warning("请选择要操作的数据项");
            }
            //弹窗配置
            this.dialogOptions = {
              title: "批量修改充值",
              width: 500,
              show: true,
              click: "getBatchUpdate",
              type: "VipBatchFill",
              data: {},
            };
          }
          break;
        case "getBatchUpdate":
          const { fillMoney, presentMoney } = this.dialogOptions.formData;
          const vipIds = this.options.check.map((item) => item.vipId);
          this.options.list = this.options.list.map((item) => {
            const curFillMoney = vipIds.includes(item.vipId) ? fillMoney : item.fillMoney;
            const curPresentMoney = vipIds.includes(item.vipId)
              ? presentMoney
              : item.presentMoney;
            return {
              ...item,
              fillMoney: curFillMoney,
              presentMoney: curPresentMoney,
              allFillMoney: fcount([item.totalUsableMoney, fillMoney, presentMoney], "+"),
            };
          });
          break;
        case "batchDel":
          {
            if (["2", "3"].includes(this.options.billStatus)) {
              return this.$message.warning("请操作未审核的单据");
            }
            const check = this.$refs.detailPage.$refs.vxeTable.$refs.xTable.getCheckboxRecords();
            if (!check.length) {
              return this.$message.warning("请选择要操作的数据项");
            }
            const vipIds = check.filter((item) => item.vipId).map((item) => item.vipId);
            this.options.list = this.options.list.filter(
              (item) => !vipIds.includes(item.vipId)
            );
            if (this.options.list.length === 0) {
              this.options.list = [{}];
            }
            await this.$nextTick();
            this.$refs.detailPage.$refs.vxeTable.$refs.xTable.clearCheckboxRow();
            this.options.check = [];
          }
          break;
        case "exportFile": // 导出
          this.download(
            "/api/system/vip/bill/vipFill/export",
            {
              billId: this.options.billId,
            },
            `会员充值导出${getTimeDate()}.xlsx`
          );
          break;
        case "quit":
          this.quit();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style></style>
