<template>
  <div class="wrap" v-loading="loading" id="wrap">
    <el-form ref="form" :model="options" :rules="rules" label-width="96px">
      <topOperatingButton
        :isExamineBtn="true"
        :showAudit="false"
        id="topOperatingButton"
        @submitForm="submitForm(false)"
        @addBill="submitForm(true)"
        @auditBill="auditForm"
        @getQuit="$router.go(-1)"
      >
        <template v-slot:specialDiyBtn>
          <el-button size="mini" @click="batchDel"> 批量删除 </el-button>
        </template>
      </topOperatingButton>
      <cardTitleCom
        cardTitle="基本信息"
        id="basicInformation"
        :billStatus="options.billStatus"
      >
        <template slot="cardContent">
          <div class="x-f marT10">
            <el-form-item label="方案编号" prop="billNo">
              <el-input
                class="inputWidth"
                size="mini"
                :disabled="true"
                v-model="options.billNo"
                placeholder="方案编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="方案日期" prop="billDate">
              <el-date-picker
                class="inputWidth"
                size="mini"
                v-model="options.billDate"
                type="date"
                placeholder="方案日期"
                :disabled="['2', '3'].includes(options.billStatus)"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="会员换卡列表">
        <template slot="cardContent">
          <div class="table">
            <!-- 可编辑表格 -->
            <EditTable :options="options" @handleEvent="handleEvent" />
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import EditTable from "@/components/editTable"; //可编辑表格
import Dialog from "@/components/Dialog";
import {
  cardChangeSave,
  cardChangeUpdate,
  cardChangeUpdateStatus,
  cardChangeGetDetail,
} from "@/api/vip/bill/cardChange";
import { getBillNo } from "@/api/codeRule";
import { getFormattedDate } from "@/utils/newDate";

export default {
  // 会员换卡
  name: "CardChangeDetail",
  dicts: ["vip_card_status"],
  components: {
    cardTitleCom,
    EditTable,
    topOperatingButton,
    Dialog,
  },
  data() {
    return {
      loading: false, //遮罩层
      //表格配置
      options: {
        billNo: undefined,
        billDate: undefined,
        curListIndex: -1, //当前表格行
        mutiSelect: true, //是否多选
        list: [{}],
        check: [], //选中的数据
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: 0, //表格高度
        status: undefined, //方案状态
        retainColumn: true, //保留表格最后一行
        tableIndex: 0,
        billStatus: undefined,
        columns: [
          {
            prop: "vipNo",
            label: "会员卡号",
            type: "remoteSelect",
            click: "clickVip",
            minWidth: 150,
            align: "center",
            option: this.$select({
              key: "vip",
              option: {
                option: {
                  label: "vipNo",
                  value: "vipNo",
                  model: "vipNo",
                  change: (propValue, row) => {
                    if (row && this.options.tableIndex != undefined) {
                      const isRepetition = this.options.list.some(
                        (item) => item.newVipId == row.vipId || item.vipId == row.vipId
                      );
                      if (!isRepetition) {
                        this.options.columns[0].option.showItem = [row];
                        this.options.list[this.options.tableIndex].vipId = row.vipId;
                        this.options.list[this.options.tableIndex].vipNo = row.vipNo;
                        this.options.list[this.options.tableIndex].vipName = row.vipName;
                        this.options.list[this.options.tableIndex].cardStatusName =
                          row.cardStatusName;
                      } else {
                        this.options.list[this.options.tableIndex] = {};
                        this.options.list[this.options.tableIndex].vipId = undefined;
                        this.options.list[this.options.tableIndex].vipNo = undefined;
                        this.options.list[this.options.tableIndex].vipName = undefined;
                        this.options.list[
                          this.options.tableIndex
                        ].cardStatusName = undefined;
                        return this.$message.error("请选择不同的会员");
                      }
                    }
                  },
                  tableChange: (propValue, row) => {
                    this.handleEvent("getSelectData", { ...row, prop: "vipNo" });
                  },
                  buttons: [
                    {
                      type: "more",
                      option: {
                        title: "选择会员",
                        width: 1250,
                        type: "TreeAndTable",
                        formData: this.$dialog({ key: "vip" }),
                      },
                    },
                  ],
                },
              },
            }).option,
            disabled: false,
            rules: true,
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "cardStatusName",
            label: "卡状态",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "newVipNo",
            label: "新会员卡号",
            type: "remoteSelect",
            click: "clickVip",
            minWidth: 110,
            align: "center",
            option: this.$select({
              key: "vip",
              option: {
                option: {
                  label: "vipNo",
                  value: "vipNo",
                  model: "newVipNo",
                  change: (propValue, row) => {
                    if (row && this.options.tableIndex != undefined) {
                      const isRepetition = this.options.list.some(
                        (item) => item.newVipId == row.vipId || item.vipId == row.vipId
                      );
                      if (!isRepetition) {
                        this.options.columns[3].option.showItem = [row];
                        this.options.list[this.options.tableIndex].newVipCardStatusName =
                          row.cardStatusName;
                        this.options.list[this.options.tableIndex].newVipId = row.vipId;
                        this.options.list[this.options.tableIndex].newVipNo = row.vipNo;
                        this.options.list[this.options.tableIndex].newVipName =
                          row.vipName;
                      } else {
                        this.options.list[
                          this.options.tableIndex
                        ].newVipCardStatusName = undefined;
                        this.options.list[this.options.tableIndex].newVipId = undefined;
                        this.options.list[this.options.tableIndex].newVipNo = undefined;
                        this.options.list[this.options.tableIndex].newVipName = undefined;
                        return this.$message.error("请选择不同的会员");
                      }
                    }
                  },
                  tableChange: (propValue, row) => {
                    this.handleEvent("getSelectData", {
                      ...row,
                      prop: "newVipNo",
                    });
                  },
                  buttons: [
                    {
                      type: "more",
                      option: {
                        title: "选择会员",
                        width: 1250,
                        type: "TreeAndTable",
                        formData: this.$dialog({ key: "vip" }),
                      },
                    },
                  ],
                },
              },
            }).option,
            disabled: false,
            rules: true,
          },
          {
            prop: "newVipName",
            label: "新会员姓名",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "newVipCardStatusName",
            label: "新卡状态",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 120,
            align: "center",
            type: "input",
            input: "sumBalance",
            disabled: false,
            notVerify: true,
          },
        ],
      },
      //弹窗配置
      dialogOptions: {
        title: "选择会员",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      rules: {
        billNo: [
          {
            required: true,
            message: "请输入方案编号",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [{ required: true, message: "请输入方案日期", trigger: "blur" }],
      },
      pageH: 0, //页面高度
      btnsH: 0, //搜索框高度
      basicH: 0, //基础框高度
      paginationH: 50, //分页高度
    };
  },
  watch: {
    "options.billStatus": {
      handler(val) {
        console.log(val, "状态");
        const disabledColumns = ["2", "3"].includes(val);
        this.options.columns[0].disabled = disabledColumns;
        this.options.columns[3].disabled = disabledColumns;
        this.options.columns[6].disabled = disabledColumns;
        this.options.status = disabledColumns;
      },
      immediate: true,
    },
  },
  activated() {
    this.handleResize(); //自适应高度
    this.getDetail();
  },
  created() {
    this.handleResize(); //自适应获取高度
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.getDetail();
  },
  beforeDestroy() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    //获取详情
    async getDetail() {
      this.reset();
      if (this.$route.query.type === "Update" && this.$route.query.billId) {
        this.loading = true;
        try {
          //获取方案详情
          const { data } = await cardChangeGetDetail(this.$route.query.billId);
          this.options.columns[3].option.showItem = data.billDetailItems;
          this.options.columns[0].option.showItem = data.billDetailItems;

          //方案赋值
          this.options = {
            ...this.options,
            ...data,
            list: data.billDetailItems,
          };
        } catch (err) {}
        this.loading = false;
      } else {
        this.getBillno();
      }
    },
    //自适应获取高度
    async handleResize() {
      setTimeout(() => {
        this.pageH = document.getElementById("wrap").offsetHeight;
        this.btnsH = document.getElementById("topOperatingButton").offsetHeight;
        this.basicH = document.getElementById("basicInformation").offsetHeight;
        this.options.tableHeight =
          this.pageH - this.btnsH - this.basicH - this.paginationH - 68;
      }, 10);
    },
    async handleEvent(type, row) {
      switch (type) {
        case "clickVip":
          if (["2", "3"].includes(this.options.billStatus)) return;
          //弹窗配置
          this.dialogOptions = {
            index: row.index,
            prop: row.prop,
            title: "选择会员",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vip" }),
          };
          break;
        case "getSelectData":
          const { check, prop } = row;
          console.log(check, prop);
          if (!check || check.length <= 0) return;

          if (prop == "vipNo") {
            this.options.columns[0].option.showItem = check;
          } else {
            this.options.columns[3].option.showItem = check;
          }

          let vipList = [];
          check.forEach((item) => {
            const isRepetition = this.options.list.some(
              (i) => i.newVipId == item.vipId || i.vipId == item.vipId
            );
            if (!isRepetition) {
              let newItem = {};
              if (prop == "vipNo") {
                newItem.vipNo = item.vipNo;
                newItem.vipId = item.vipId;
                newItem.vipName = item.vipName;
                newItem.cardStatusName = item.cardStatusName;
              } else {
                newItem.newVipNo = item.vipNo;
                newItem.newVipId = item.vipId;
                newItem.newVipCardStatusName = item.cardStatusName;
                newItem.newVipName = item.vipName;
              }
              vipList.push(newItem);
            } else return false;
          });
          for (let i = 0; i < vipList.length; i++) {
            let item = this.options.list[this.options.tableIndex + i];
            item = item ? { ...item, ...vipList[i] } : { ...vipList[i] };
            this.$set(this.options.list, this.options.tableIndex + i, item);
          }
          break;
        case "tableIndex":
          this.options.tableIndex = row;
          break;
        default:
          break;
      }
    },
    // 保存
    async submitForm(isAdd) {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let form = {
            billNo: this.options.billNo, // 方案号
            billId: this.options.billId, // 方案ID
            billDate: this.options.billDate, // 方案时间
            billDetailItems: this.options.list, // 列表
          };
          form.billDetailItems = form.billDetailItems.filter(
            (item) => item.vipId && item.newVipId
          );
          if (form.billId) {
            try {
              const { data } = await cardChangeUpdate(form);
              this.options.billStatus = data.billStatus;
              this.$message.success("修改成功");
              //新增
              if (isAdd) {
                this.reset();
                this.getBillno();
              }
            } catch (err) {}
          } else {
            try {
              const { data } = await cardChangeSave(form);
              this.options.billStatus = data.billStatus;
              this.options.billId = data.billId;
              this.$message.success("新增成功");
              //新增
              if (isAdd) {
                this.reset();
                this.getBillno();
              }
            } catch (err) {}
          }
        } else this.$message.error("请把带有红星的必填项输入完整!");
      });
    },
    // 审核/反审核  按钮
    async auditForm() {
      if (this.options.billStatus == "0") {
        this.loading = true;
        try {
          const { data } = await cardChangeUpdateStatus({
            billIds: [this.options.billId],
            billStatus: 2,
          });
          this.options.billStatus = "2";
          this.$message.success(`审核成功`);
        } catch (err) {}
        this.loading = false;
      } else {
        this.$message.warning("请操作未审核的方案");
      }
    },
    //批量删除
    batchDel() {
      if (["2", "3"].includes(this.options.billStatus)) {
        return this.$message.warning("请操作未审核的方案");
      }
      if (!this.options.check.length) {
        return this.$message.warning("请选择要操作的数据项");
      }
      this.$confirm("确定删除选中的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.options.check.forEach((item) => {
            let index = this.options.list.indexOf(item);
            this.options.list.splice(index, 1);
          });
          if (!this.options.list) this.options.list = [{}];
        })
        .catch(() => {});
    },
    // 获取订单编号
    async getBillno() {
      this.options.billNo = await getBillNo(150202);
      this.options.billDate = getFormattedDate(2, "-");
    },
    //初始化数据
    reset() {
      //表格配置
      this.options = {
        ...this.options,
        billId: undefined,
        billNo: undefined,
        billDate: undefined,
        billStatus: undefined,
        curListIndex: -1, //当前表格行
        mutiSelect: true, //是否多选
        list: [{}],
        check: [], //选中的数据
      };
      //弹窗配置
      this.dialogOptions = {
        title: "选择会员",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      };
      this.resetForm("form");
    },
  },
};
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
}
.table {
  padding: 10px;
}
</style>
